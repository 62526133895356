<template>
  <div class="center">
    <!-- 主体 -->
    <main class="mt-10">
      <van-cell>
        <template #icon>
          <img :src="userInfo.avatar" class="imege" />
        </template>
        <div>
          <div>
            <span class="f16 ml-20">{{userInfo.nickname}}</span>
            <van-tag type="success" class="ml-20">{{userInfo.type}}</van-tag>
          </div>
          <div class="ml-20" style="margin-top:8px;">
            <span style="color:#333333" v-if="userInfo.mobile">{{userInfo.mobile}}</span>
            <span style="color:red" v-if="!userInfo.mobile">未绑定手机号</span>
          </div>
        </div>
      </van-cell>
      <!-- <van-cell title="实名认证" isLink to="/weixin/verify" /> -->
      <div class="bg-fff mt-10" v-if="userInfo.type == '业务员' || userInfo.type == '企业员工'">
        <van-cell
          v-if="userInfo.type == '业务员' || userInfo.type == '企业员工'"
          icon="newspaper-o"
          title="企业内刊"
          isLink
          to="/article/list"
        ></van-cell>
        <van-cell
          v-if="userInfo.type == '业务员'"
          icon="bar-chart-o"
          title="业务查询"
          isLink
          to="/business/list"
        ></van-cell>
      </div>
      <div class="bg-fff mt-10">
        <van-cell icon="orders-o" title="煎配记录" isLink to="/cook/list"></van-cell>
        <van-cell icon="todo-list-o" title="预约记录" isLink to="/reservation/history"></van-cell>
      </div>

      <van-button
        class="logout"
        @click="edit"
        block
        round
        type="info"
      >{{!userInfo.mobile ? '用户注册':'编辑个人信息'}}</van-button>
      <!-- <van-button class="logout" @click="logout" block round type="info" >退出账户</van-button> -->
    </main>

    <Footer page="my" />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";
export default {
  components: {
    Footer,
  },

  computed: {
    ...mapGetters(["userId", "isLogin"]),
  },

  data() {
    return {
      userInfo: {
        nickname: null,
        mobile: null,
      },
    };
  },

  methods: {
    edit() {
      this.$router.push("edit");
    },
  },
  created() {
    console.log(" index created >>> ");
  },
  mounted() {
    console.log(" index mounted >>> ");
    this.userInfo = this.$user;
  },
};
</script>

<style lang="less" scoped>
/* 本页公共样式 */
.gray {
  color: #797d82;
}

.bott0 {
  bottom: 0;
}

.orange {
  color: #ff5f16;
}

.yellow {
  color: #ffb232;
}

.bd-gray {
  border-bottom: 1px solid #f5f5f5;
}

.login_form {
  margin-top: 46px;
}

// 用户名和头像
.users {
  top: 55px;
  left: 22px;
  position: absolute;

  // 头像
  .imege {
    height: 65px;
    border-radius: 50%;
  }

  .fff {
    font-size: 22px;
  }
}
// 头像
.imege {
  height: 65px;
  border-radius: 50%;
}

// 导航
.nav {
  height: 75px;

  .iconfont {
    font-weight: bold;
  }

  .cit {
    height: 26px;
  }
}

.logout {
  width: 80%;
  margin: 50px auto 0;
}

// 主体
main {
  .list {
    height: 50px;

    .image {
      height: 20px;
    }
  }

  .foun {
    font-size: 12px;
  }
}

/* 底部 */
.footer {
  height: 50px;
}
</style>